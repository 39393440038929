import { Translation } from 'Common/components/localization';
import * as React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Formatter } from 'Common/utils';
import { useViewport } from 'Common/hooks';
import { Grid, Header, Row, Cell } from 'Common/components/ui/Grid';
import ItemDetailsModal from './ItemDetailsModal';

export default function ProductChildTable({ clearFacets, onSelectChild, productChildren, selectedChild }) {
    const viewport = useViewport();
    const isMobile = viewport.is.lt('lg');
    const quickViewRef = React.useRef(null);
    const [selectedVariant, setSelectedVariant] = React.useState(null);
    const [showModal, setShowModal] = React.useState(false);

    const isPriceExists = productChildren.filter((variation) => variation.Price.ListPrice.Amount > 0).length > 0;

    const closeModal = React.useCallback(() => {
        setShowModal(false);
        setSelectedVariant(false);
    }, []);

    React.useEffect(() => {
        if (selectedVariant && quickViewRef.current) {
            quickViewRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selectedVariant]);

    const handleVariantSelection = React.useCallback(
        (child) => {
            if (child?.Code === selectedVariant) {
                setSelectedVariant(null);
                setShowModal(false)
                clearFacets();
            } else {
                setSelectedVariant(child.Code);
                onSelectChild(child);
                setShowModal(true);
            }
        },
        [clearFacets, onSelectChild, selectedVariant]
    );

    return productChildren?.length ? (
        <div className={cx('ProductChildTable')}>
            <Grid className="ProductChildTable__Grid">
                <Header>
                    {!isMobile ? (
                        <Cell className="text-left pl-4">
                            <Translation id="Commerce.Product.ChildrenTable.Code" />
                        </Cell>
                    ) : null}
                    <Cell className="text-center">
                        <Translation id="Commerce.Product.ChildrenTable.Description" />
                    </Cell>
                    {!isMobile ? (
                        <Cell className="text-center">
                            <Translation id="Commerce.Product.ChildrenTable.Price" />
                        </Cell>
                    ) : null}
                    <Cell width="2rem" />
                </Header>
                {productChildren.map((child) => {
                    const isOpen = selectedVariant === child.Code;

                    return [
                        <Row key={child.Code} onClick={() => handleVariantSelection(child)}>
                            {!isMobile ? (
                                <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__code text-left pl-4`}>
                                    {child.Code}
                                </Cell>
                            ) : null}
                            <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__name text-left`}>
                                {child.DisplayName}
                            </Cell>
                            {isPriceExists && !isMobile ? (
                                <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__price text-center`}>
                                    {child.Price.ListPrice.Amount > 0
                                        ? Formatter.currency(
                                              child.Price.ListPrice.Amount,
                                              child.Price.ListPrice.Currency
                                          )
                                        : null}
                                </Cell>
                            ) : null}
                            <Cell className={`${isOpen ? 'Grid__cell--selected' : ''} Grid__dropdown text-center`}>
                                <i className={cx('fas', `fa-chevron-${isOpen ? 'up' : 'down'}`)} />
                            </Cell>
                        </Row>,
                    ].filter(Boolean);
                })}
            </Grid>
            {showModal ? (
                <ItemDetailsModal 
                    selectedChild={selectedChild} 
                    closeModal={closeModal}
                    showModal={showModal}
                    clearFacets={clearFacets}
                    setShowModal={setShowModal} 
                />
            ) : null}
        </div>
    ) : null;
}

ProductChildTable.propTypes = {
    clearFacets: px.func,
    onSelectChild: px.func,
    productChildren: px.arrayOf(
        px.shape({
            Code: px.string,
            DisplayName: px.string,
        })
    ),
    selectedChild: px.object
};
