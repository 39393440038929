import { createSelector } from 'reselect';

export const getCompareState = (state) => state.compare;

export const getCompareItems = createSelector(getCompareState, (compare = { items: [] }) => compare.items)

export const getCompareItemsDetails = createSelector(getCompareState, (compareState = { items: [] }) =>
    compareState.items.map((item) => {
        const activeChild = item.CatalogEntity.Children.find((child) => child.Code === item.Code);

        return {
            Code: item.Code,
            ListPrice: item.ListPrice,
            SalePrice: item.SalePrice,
            Title: item.CatalogEntity.DisplayName || item.CatalogEntity.Name,
            Url: item.CatalogEntity.ContentUrl,
            ImageUrl: item.CatalogEntity.DefaultImageUrl,
            Attributes: item.CatalogEntity.Attributes.concat(activeChild?.Attributes || [])
                .filter((attr) => attr.IsSpecification)
                .reduce((acc, attr) => ({ ...acc, [attr.AttributeName]: attr }), {}),
            StockStatus: item.StockStatus,
        };
    })
);

export const getSpecDefinitions = createSelector(getCompareItemsDetails, (items = []) =>
    items
        .flatMap((item) => Object.entries(item.Attributes))
        .reduce(
            (acc, [attrName, newAttr]) =>
                acc.find((existingAttr) => existingAttr.AttributeName === attrName) ? acc : [...acc, newAttr],
            []
        )
        .sort((attr1, attr2) => attr1.Order - attr2.Order)
);

export const getLoading = createSelector(
    getCompareState,
    (compareState = { isLoading: false }) => compareState.isLoading
);

export const getAlert = createSelector(
    getCompareState,
    (
        compareState = {
            alert: {
                message: {
                    translationKey: undefined,
                    params: {},
                    type: '',
                },
                timestamp: undefined,
            },
        }
    ) => compareState.alert
);

export const getError = createSelector(getCompareState, (compareState = { isError: false }) => compareState.isError);
