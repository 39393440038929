import { useMemo } from 'react';

const useItemAttributesTabs = (attributes = []) => {
    return useMemo(() => {
        let content = [];

        attributes?.forEach((attribute) => {
            if (attribute.AttributeName === 'ItemMaterial') {
                content = content.concat([
                    {
                        name: "Commerce.Product.ItemDetailsCard.Attribute.Materials.Label",
                        value: attribute.Values,
                    },
                ]);
            }
            if (attribute.AttributeName === 'ItemBatteryMisc') {
                content = content.concat([
                    {
                        name: "Commerce.Product.ItemDetailsCard.Attribute.Batteries.Label",
                        value: attribute.Values,
                    },
                ]);
            }
            if (attribute.AttributeName === 'ItemIngredients') {
                content = content.concat([
                    {
                        name: "Commerce.Product.ItemDetailsCard.Attribute.Ingredients.Label",
                        value: attribute.Values,
                    },
                ]);
            }
            if (attribute.AttributeName === 'ItemAnalysis') {
                content = content.concat([
                    {
                        name: "Commerce.Product.ItemDetailsCard.Attribute.Analysis.Label",
                        value: attribute.Values,
                    },
                ]);
            }
        });
        return content;
    }, [attributes]);
};

export default useItemAttributesTabs;
