import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import px from 'prop-types';
import { v4 } from 'uuid';
import { useTranslator, useViewport } from 'Common/hooks';
import { youtubeThumbnailResolver } from 'Common/utils';
import { VideoPlayer, VideoPlayerModal } from '../ui';

export default function VideoGallery({
    arrowColor = 'default',
    cardsToShow = 3,
    emphasizeCenter = false,
    shouldOverhang = false,
    videos,
}) {
    const id = React.useRef(`video-${v4()}`);
    const viewport = useViewport();
    const isMobile = useMemo(() => viewport.is.lt('md'), [viewport]);
    const thumbTranslator = useTranslator('Commerce.Product.Gallery.SelectThumb.Label');
    const [showModal, setShowModal] = useState(false);
    const [videoData, setVideoData] = useState(null);

    const videosCount = videos.length;

    let settings = {
        slidesToScroll: 1,
        centerPadding: emphasizeCenter ? '50px' : '0',
        arrows: true,
        centerMode: false,
        focusOnSelect: true,
        infinite: true,
        autoplay: false,
        slidesToShow: cardsToShow,
        responsive: [
            {
                breakpoint: 1024,
                settings: { slidesToShow: Math.max(cardsToShow - 1, 1) },
            },
            {
                breakpoint: 800,
                settings: { slidesToShow: Math.min(cardsToShow - 1, 2) },
            },
            {
                breakpoint: 500,
                settings: { slidesToShow: 1 },
            },
        ],
    };

    if (videosCount === 1) {
        settings = null;
    } else if (videosCount === 2) {
        settings.slidesToShow = 2;
        settings.centerMode = false;
    } else if (videosCount >= 3) {
        settings.slidesToShow = 2;
        settings.centerMode = !isMobile ? true : false;
        settings.centerPadding = !isMobile ? '50px' : '0';
    }

    const containerClasses = [
        'VideoGallery',
        'carousel',
        shouldOverhang ? 'carousel--overhang' : '',
        `carousel--arrow-color-${arrowColor}`,
    ]
        .filter(Boolean)
        .join(' ');

    const onCloseModal = useCallback(() => {
        setShowModal(false);
        setVideoData(null);
    }, []);

    const onClickGalleryItem = React.useCallback((selectedVideo) => {
        setVideoData({ url: selectedVideo.Url, previewImage: selectedVideo.ThumbnailUrl });
        setShowModal(true);
    }, []);

    useLayoutEffect(() => {
        youtubeThumbnailResolver(`#${id.current} .VideoGallery__videoThumb`);
    }, [id, videoData?.url]);

    return (
        <div className={containerClasses}>
            <div>
                {videosCount === 1 ? (
                    <VideoPlayer url={videos[0]?.Url} previewImage={videos[0]?.ThumbnailUrl} playsInline />
                ) : settings ? (
                    <Slider {...settings}>
                        {videos.map((m) =>
                            m?.Url && m.MediaType === 'video' && m.ThumbnailUrl ? (
                                <div key={m.Url}>
                                    <div
                                        onClick={() => onClickGalleryItem(m)}
                                        aria-label={thumbTranslator(m)}
                                        id={id.current}
                                    >
                                        <div
                                            className={cx('VideoGallery__videoThumb')}
                                            style={{
                                                backgroundImage: `url(${m.ThumbnailUrl})`,
                                            }}
                                        >
                                            <i className="far fa-play-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        )}
                    </Slider>
                ) : (
                    <div>No slides to display</div>
                )}
            </div>
            <VideoPlayerModal videoProps={videoData} show={showModal} onClose={onCloseModal} />
        </div>
    );
}

VideoGallery.propTypes = {
    arrowColor: px.string,
    cardsToShow: px.number,
    emphasizeCenter: px.bool,
    shouldOverhang: px.bool,
    shouldWrap: px.bool,
    videos: px.arrayOf(px.object),
};
