import * as React from 'react';
import px from 'prop-types';
import { useViewport, useItemAttributesTabs } from 'Common/hooks';
import { Formatter } from 'Common/utils';
import { Translation } from 'Common/components/localization';
import { ProductGallery } from 'Common/containers/product';

export default function ItemDetailsCard({ itemData }) {
    const viewport = useViewport();
    const isMobile = React.useMemo(() => viewport.is.lt('lg'), [viewport]);
    const itemContent = useItemAttributesTabs(itemData?.Attributes);

    return itemData ? (
        <div className="ItemDetailsCard container-fluid">
            <div className='ItemGallery'>
                <ProductGallery useImageModal={false} slidesToShow={isMobile ? 3 : 5} noGalleryMobile={true} noGallery={false}/>
            </div>
            <div className="ItemDetailsCard__details-container">
                <div className="ItemDetailsCard__detail-row">
                    <div className="ItemDetailsCard__detail-header">
                        <Translation id="Commerce.Product.ChildrenTable.Code" />
                        {':'}
                    </div>
                    <div>{itemData.Code}</div>
                </div>
                <div className="ItemDetailsCard__detail-row">
                    <div className="ItemDetailsCard__detail-header">
                        <Translation id="Commerce.Product.ChildrenTable.Price" />
                        {':'}
                    </div>
                    <div>
                        {itemData?.Price ? (
                            <>
                                {Formatter.currency(
                                    itemData?.Price?.ListPrice.Amount,
                                    itemData?.Price?.ListPrice.Currency
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
            {itemContent?.map((a) => {
                return (
                    <div key={a.name} className="attribute">
                        <div className="attribute__header">
                            <Translation id={a.name} />
                        </div>
                        <div className="attribute__value">{a.value}</div>
                    </div>
                );
            })}
        </div>
    ) : null;
}

ItemDetailsCard.propTypes = {
    itemData: px.object,
};
